import React from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { LogOutIcon } from "evergreen-ui";
import { Button, colors, Container, navBarHeight, Logo as LogoIcon, navBarWidth, spacing, navBarShadow } from "ui-kit";
import { useAuthStore } from "../../stores";
import useScroll from "../../utils/use-scroll";
import LanguagePicker from "./language-picker";

const SCROLL_SHADOW_TRESHOLD = 100;

const Wrapper = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 ${spacing.m};
  box-sizing: border-box;
  z-index: 5;
  transition: all 0.3s;

  & button {
    margin-left: 5px;
  }
`;

const NavContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${navBarHeight};
  max-width: ${navBarWidth} !important;
  overflow: visible;

  & div {
    display: flex;
    align-items: center;
  }
`;

const Logo = styled(LogoIcon)`
  margin-right: ${spacing.xs};
`;

const LogoutButton = styled(Button)`
  height: 40px;
  padding: 0;
  padding-right: 10px;
  margin-right: -10px;
`;

interface NavProps extends React.HTMLAttributes<HTMLDivElement> {
  lightBackground?: boolean;
}

const _Nav = observer((props: NavProps) => {
  const { scrollY } = useScroll();
  const { t } = useTranslation("general");
  const authStore = useAuthStore();

  const style = {
    ...props.style,
    boxShadow: scrollY > SCROLL_SHADOW_TRESHOLD ? navBarShadow : undefined,
  };

  return (
    <Wrapper {...props} style={style}>
      <NavContainer>
        <div>
          <Logo /> {t("admin")}
        </div>

        <div>
          <LanguagePicker />
          {authStore.isAuthenticated && (
            <LogoutButton onClick={() => authStore.logout()} appearance="minimal" iconAfter={<LogOutIcon />} />
          )}
        </div>
      </NavContainer>
    </Wrapper>
  );
});

export const Nav = styled(_Nav)`
  background-color: ${(props) => (props.lightBackground ? colors.background : colors.white)};
`;
