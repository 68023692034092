import React, { useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { Spinner, Table as EvergreenTable } from "evergreen-ui";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Container, spacing } from "ui-kit";
import { useMutation, useQuery } from "react-query";
import { DateTime } from "luxon";
import _ from "lodash";
import { Assessment, FormEntry } from "shared";
import { usePatientsStore } from "../../stores";
import { PageWrapper } from "../../components";
import AssessmentSideSheet from "./assessment-side-sheet";
import PatientsExportButton from "../../components/patients-export-button";

const PatientFileContainer = styled(Container)`
  max-width: 900px !important;
  overflow: hidden;
  & h1 {
    margin-bottom: ${spacing.m};
  }
  & h2 {
    margin-bottom: ${spacing.s};
  }
`;

const Table = styled(EvergreenTable)`
  margin-bottom: ${spacing.s};
  overflow: auto;
`;

const CenteredRow = styled.div`
  text-align: center;
  margin-top: ${spacing.xl};

  & > div {
    margin: auto;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${spacing.s};
`;

export const PatientFilePage = observer(() => {
  const { t, i18n } = useTranslation("patients");
  const { language } = i18n;
  const { id } = useParams();
  const patientsStore = usePatientsStore();

  // States

  const [showSideSheet, setShowSideSheet] = useState(false);

  // Network

  const { data: patient, isLoading } = useQuery("fetch-single-patient", () => patientsStore.fetchSinglePatient(id!));
  const { data: assessment, mutateAsync: fetchAssessment } = useMutation(
    "fetch-patient-assessment",
    (assesmentId: number) => patientsStore.fetchPatientAssessment(id!, assesmentId)
  );

  // Rendering

  const loader = (
    <CenteredRow>
      <Spinner />
    </CenteredRow>
  );

  const fullName = _.get(patient, "fullName");
  const gender = _.get(patient, "gender");
  // TODO: Move this to a virtual field backend
  const age = Math.floor(
    Math.abs(DateTime.fromISO(_.get(patient, "dateOfBirth", "") as string).diffNow("years").years)
  );
  const createdAt = DateTime.fromISO(_.get(patient, "createdAt", "") as string)
    .setLocale(language)
    .toLocaleString(DateTime.DATETIME_MED);
  const email = _.get(patient, "email");
  const phoneNumber = _.get(patient, "phoneNumber");
  const assessments = _.get(patient, "assessments", []);
  const uniqueForms = _.chain(assessments).flatMap("formEntries").map("form").uniqBy("key").value();

  const content = (
    <>
      <HeaderContainer>
        <h1>{fullName}</h1>
        <PatientsExportButton patientId={_.get(patient, "id")} fullName={fullName} />
      </HeaderContainer>
      <h2>{t("information")}</h2>
      <Table>
        <Table.Head>
          <Table.TextHeaderCell>{t("gender")}</Table.TextHeaderCell>
          <Table.TextHeaderCell>{t("age")}</Table.TextHeaderCell>
          <Table.TextHeaderCell>{t("created")}</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body>
          <Table.Row>
            <Table.TextCell>{gender}</Table.TextCell>
            <Table.TextCell>{age}</Table.TextCell>
            <Table.TextCell>{createdAt}</Table.TextCell>
          </Table.Row>
        </Table.Body>
      </Table>
      <Table>
        <Table.Head>
          <Table.TextHeaderCell>{t("email")}</Table.TextHeaderCell>
          <Table.TextHeaderCell>{t("phoneNumber")}</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body>
          <Table.Row>
            <Table.TextCell>
              <a target="_blank" href={`mailto:${email}`} rel="noreferrer">
                {email}
              </a>
            </Table.TextCell>
            <Table.TextCell>
              <a target="_blank" href={`tel:${phoneNumber}`} rel="noreferrer">
                {phoneNumber}
              </a>
            </Table.TextCell>
          </Table.Row>
        </Table.Body>
      </Table>
      <h2>{t("medicalHistory")}</h2>
      <Table>
        <Table.Head>
          {uniqueForms.map((form) => (
            <Table.TextHeaderCell>{_.get(form, "key")}</Table.TextHeaderCell>
          ))}
          <Table.TextHeaderCell flexBasis={60}>{t("date")}</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body>
          {assessments.map((assessment: Assessment) => (
            <Table.Row
              isSelectable
              onSelect={() => {
                fetchAssessment(_.get(assessment, "id"));
                setShowSideSheet(true);
              }}>
              {uniqueForms.map((form) => {
                const formEntry = _.chain(assessment)
                  .get("formEntries", [])
                  .value()
                  .find((entry: FormEntry) => entry.formKey === form.key);

                // TODO: Move this to a virtual field in the BE
                const score =
                  formEntry && formEntry.scoreCategory != null
                    ? _.get(["A", "B", "C", "D"], formEntry.scoreCategory)
                    : "-";
                return <Table.TextCell>{score}</Table.TextCell>;
              })}
              <Table.TextCell flexBasis={60}>
                {DateTime.fromISO(_.get(assessment, "createdAt", "") as string)
                  .setLocale(language)
                  .toLocaleString(DateTime.DATETIME_MED)}
              </Table.TextCell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );

  // TODO: integrate todo from backend virtual field

  return (
    <PageWrapper withNav lightBackground>
      <PatientFileContainer>
        <>
          {isLoading && loader}
          {patient && content}
        </>
      </PatientFileContainer>
      <AssessmentSideSheet assessment={assessment} shown={showSideSheet} setShown={setShowSideSheet} />
    </PageWrapper>
  );
});
