import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Pagination, Spinner, Table } from "evergreen-ui";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Container, spacing } from "ui-kit";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import { authenticatedRoutes } from "../../utils/routes";
import { usePatientsStore } from "../../stores";
import { PageWrapper } from "../../components";
import PatientsExportButton from "../../components/patients-export-button";

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${spacing.s};

  & > h1 {
    & > div {
      display: inline-block;
      margin-left: ${spacing.s};
      width: 24px;
      height: 24px;
    }
  }
`;

export const PatientsPage = observer(() => {
  const { t, i18n } = useTranslation("patients");
  const { language } = i18n;
  const patientsStore = usePatientsStore();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  // States

  const [nameFilter, setNameFilter] = useState("");
  const [page, setPage] = useState(1);

  // Network

  const { refetch, isFetching } = useQuery("fetch-patients", () => patientsStore.fetchPatients(page, nameFilter));

  // Effects

  useEffect(() => {
    if (page > patientsStore.pageCount) setPage(Math.max(patientsStore.pageCount, 1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientsStore.pageCount]);

  useEffect(() => {
    queryClient.cancelQueries("fetch-patients");
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameFilter, page]);

  // Rendering

  const patientsTable = (
    <div>
      <Table>
        <Table.Head>
          <Table.SearchHeaderCell
            value={nameFilter}
            placeholder={t("nameFilterPlaceholder")}
            onChange={setNameFilter}
          />
          <Table.TextHeaderCell>{t("gender")}</Table.TextHeaderCell>
          <Table.TextHeaderCell>{t("created")}</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body>
          {(patientsStore.patients || []).map(({ id, firstName, lastName, gender, createdAt }) => (
            <Table.Row
              key={id}
              isSelectable
              onSelect={() => navigate(authenticatedRoutes.patientFile.path.replace(":id", id || ""))}>
              <Table.TextCell>
                {firstName} {lastName}
              </Table.TextCell>
              <Table.TextCell>{gender}</Table.TextCell>
              <Table.TextCell>
                {typeof createdAt === "string" &&
                  DateTime.fromISO(createdAt).setLocale(language).toLocaleString(DateTime.DATETIME_MED)}
              </Table.TextCell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <Pagination
        page={page}
        totalPages={patientsStore.pageCount}
        onPageChange={setPage}
        onPreviousPage={() => setPage(page - 1)}
        onNextPage={() => setPage(page + 1)}
      />
    </div>
  );

  return (
    <PageWrapper withNav lightBackground>
      <Container>
        <Header>
          <h1>
            {t("patients")}
            {isFetching && <Spinner />}
          </h1>
          <PatientsExportButton />
        </Header>
        {patientsStore.patients && patientsTable}
      </Container>
    </PageWrapper>
  );
});
