import React from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { useMutation } from "react-query";
import { Field as FormikField, FieldProps, Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import { Button, Container, Field, Input, spacing } from "ui-kit";
import { useAuthStore } from "../../stores";
import { PageWrapper, Row } from "../../components";
import { useNavigate } from "react-router-dom";
import { unauthenticatedRoutes } from "../../utils/routes";

const LoginContainer = styled(Container)`
  margin-top: ${spacing.xxl} !important;

  & h1 {
    margin-bottom: ${spacing.l};
  }
`;

interface LoginFormValues {
  email: string;
  password: string;
}

export const LoginPage = () => {
  const { t } = useTranslation("general");
  const authStore = useAuthStore();
  const navigate = useNavigate();

  // Network

  const { mutateAsync: login, isLoading } = useMutation(({ email, password }: LoginFormValues) =>
    authStore.login(email, password)
  );

  // Handlers

  const onSubmit = async ({ email, password }: LoginFormValues) => {
    const { sessionKey } = await login({ email, password });
    if (sessionKey != null) navigate(`${unauthenticatedRoutes.changePassword.path}?token=${sessionKey}&email=${email}`);
  };

  // Rendering

  const validationSchema = Yup.object().shape({
    email: Yup.string().email(t("invalidEmailError")).required(t("requiredFieldError")),
    password: Yup.string().required(t("requiredFieldError")),
  });

  return (
    <PageWrapper withNav lightBackground>
      <LoginContainer>
        <h1>
          {t("admin")} {t("login")}
        </h1>
        <Formik<LoginFormValues>
          initialValues={{ email: "", password: "" }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}>
          {({ errors, touched, isValid }) => {
            return (
              <Form>
                <Row>
                  <Field label={t("email")} error={touched.email ? errors.email : undefined}>
                    <FormikField name="email">
                      {({ field }: FieldProps) => (
                        <Input {...field} type="email" isInvalid={touched.email && errors.email != null} />
                      )}
                    </FormikField>
                  </Field>
                </Row>
                <Row>
                  <Field label={t("password")} error={touched.password ? errors.password : undefined}>
                    <FormikField name="password">
                      {({ field }: FieldProps) => (
                        <Input {...field} type="password" isInvalid={touched.password && errors.password != null} />
                      )}
                    </FormikField>
                  </Field>
                </Row>
                <Row>
                  <Button appearance="primary" type="submit" disabled={!isValid} isLoading={isLoading}>
                    {t("login")}
                  </Button>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </LoginContainer>
    </PageWrapper>
  );
};
