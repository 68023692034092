import axios, { AxiosRequestConfig, AxiosError } from "axios";
import { toaster } from "evergreen-ui";
import _ from "lodash";
import { AuthStore } from "./auth";
import i18n from "../translations";
import { PatientsStore } from "./patients";

axios.defaults.baseURL = process.env.REACT_APP_API_URL || "http://localhost:3000/dev/api-admin";

const isAxiosError = (error: any): error is AxiosError => {
  return error && error.isAxiosError;
};

export class RootStore {
  public authStore: AuthStore;
  public patientsStore: PatientsStore;

  constructor() {
    this.authStore = new AuthStore(this);
    this.patientsStore = new PatientsStore(this);
  }

  public async executeRequest<ResponseData>(
    this: RootStore,
    options: AxiosRequestConfig
  ): Promise<ResponseData | undefined> {
    const { language, t } = i18n;
    const defaultErrorMessage = t("error", { ns: "general" });

    try {
      if (!options.headers) options.headers = {};
      options.headers["accept-language"] = language;
      if (this.authStore.authToken != null) options.headers["x-access-token"] = this.authStore.authToken;

      const { data } = await axios.request(options);
      return data as ResponseData;
    } catch (e) {
      console.log(e);

      if (isAxiosError(e)) {
        const status = e.response?.status;
        const message = _.get(e, "response.data.message", defaultErrorMessage);

        if (status === 401) {
          this.authStore.logout();
          toaster.danger(message);
        } else if (status === 400) {
          // TODO: There might be more assertions needed if other bad requests error causes
          const validationString: string = _.get(e, "response.data.error", "");
          toaster.danger(message, { description: validationString });
        } else {
          toaster.danger(message);
        }
      } else {
        toaster.danger(defaultErrorMessage);
      }
    }
  }
}
