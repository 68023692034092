import React from "react";
import styled from "styled-components";
import { SideSheet, Spinner, Table as EvergreenTable } from "evergreen-ui";
import { useTranslation } from "react-i18next";
import { Container, spacing } from "ui-kit";
import _ from "lodash";
import { Assessment, mapTextToAcceptedLanguage } from "shared";
import { useWindowSize } from "../../utils/use-window-size";
import { getDurationWithUnits } from "../../utils/datetime";
import { capitalizeFirstLetter, generationEnumerationStringFromArray } from "../../utils/string";

const SheetContainer = styled(Container)`
  padding: ${spacing.l} ${spacing.m} !important;
  max-width: 900px !important;

  & h1 {
    margin-bottom: ${spacing.m};
  }
  & h2 {
    margin-bottom: ${spacing.s};
  }
`;

const Table = styled(EvergreenTable)`
  margin-bottom: ${spacing.s};
`;

const CenteredRow = styled.div`
  text-align: center;
  margin-top: ${spacing.xl};

  & > div {
    margin: auto;
  }
`;

interface AssessmentSideSheetProps {
  assessment?: Assessment;
  shown: boolean;
  setShown: (shown: boolean) => void;
}

const AssessmentSideSheet = ({ assessment, shown, setShown }: AssessmentSideSheetProps) => {
  const { t, i18n } = useTranslation("patients");
  const { language } = i18n;
  const lang = mapTextToAcceptedLanguage(language);
  const { width: pageWidth } = useWindowSize();

  // Rendering

  const loader = (
    <CenteredRow>
      <Spinner />
    </CenteredRow>
  );

  // Build contact schedule string
  const contactDays = _.get(assessment, "contactDays", []) || [];
  const contactTime = _.get(assessment, "contactTime", []) || [];
  const translatedContactDays = contactDays.map(t) as string[];
  const translatedContactTime = contactTime.map(t) as string[];
  const contactSchedule = `${generationEnumerationStringFromArray(translatedContactDays, t("and"))} ${t(
    "inThe"
  )} ${generationEnumerationStringFromArray(translatedContactTime, t("and"))}`;

  const contactInfo = (
    <>
      <h2>{t("contactInfo")}</h2>
      <Table>
        <Table.Head>
          <Table.TextHeaderCell>{t("contactPerson")}</Table.TextHeaderCell>
          <Table.TextHeaderCell>{t("contactName")}</Table.TextHeaderCell>
          <Table.TextHeaderCell>{t("contactSchedule")}</Table.TextHeaderCell>
          <Table.TextHeaderCell>{t("contactPhoneNumber")}</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body>
          <Table.Row>
            <Table.TextCell>{t(_.get(assessment, "contactPerson", "") || "")}</Table.TextCell>
            <Table.TextCell>{_.get(assessment, "contactName", "")}</Table.TextCell>
            <Table.TextCell>{capitalizeFirstLetter(contactSchedule)}</Table.TextCell>
            <Table.TextCell>
              <a target="_blank" href={`tel:${_.get(assessment, "contactPhoneNumber", "")}`} rel="noreferrer">
                {_.get(assessment, "contactPhoneNumber", "")}
              </a>
            </Table.TextCell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );

  const forms = _.get(assessment, "formEntries", []).map((formEntry) => (
    <>
      <h2>{_.get(formEntry, `form.multilingualName.${lang}`)}</h2>
      <Table>
        <Table.Head>
          <Table.TextHeaderCell>{t("scoreTotal")}</Table.TextHeaderCell>
          <Table.TextHeaderCell>{t("score")}</Table.TextHeaderCell>
          <Table.TextHeaderCell>{t("duration")}</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body>
          <Table.Row>
            <Table.TextCell>{_.get(formEntry, "score")}</Table.TextCell>
            <Table.TextCell>
              {/*TODO: Use virtual field for this*/}
              {["A", "B", "C", "D"][_.get(formEntry, "scoreCategory", 0)]}
            </Table.TextCell>
            <Table.TextCell>{getDurationWithUnits(_.get(formEntry, "duration", 0), t)}</Table.TextCell>
          </Table.Row>
        </Table.Body>
      </Table>
      <Table>
        <Table.Head>
          <Table.TextHeaderCell flexBasis={170}>{t("field")}</Table.TextHeaderCell>
          <Table.TextHeaderCell>{t("answer")}</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body>
          {_.get(formEntry, "answers", []).map((answer) => (
            <Table.Row>
              <Table.TextCell flexBasis={170}>{_.get(answer, `field.multilingualText.${lang}`)}</Table.TextCell>
              <Table.TextCell>{_.get(answer, `option.multilingualText.${lang}`)}</Table.TextCell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  ));

  const content = (
    <>
      {_.get(assessment, "contactPreferencesAreSet", false) && contactInfo}
      {forms}
    </>
  );

  // Not a fan of this method for determining the width but evergreen components are not built so well, especially this one
  const maxWidth = pageWidth * 0.85;
  const width = `${Math.min(900, maxWidth)}px`;

  return (
    <SideSheet isShown={shown} onCloseComplete={() => setShown(false)} width={width}>
      <SheetContainer>
        {!assessment && loader}
        {assessment && content}
      </SheetContainer>
    </SideSheet>
  );
};

export default AssessmentSideSheet;
