import styled from "styled-components";
import { spacing } from "ui-kit";

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${spacing.s};

  & button {
    margin-top: ${spacing.s};
    padding: 0 50px;
    max-width: 100%;
  }
`;
