import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { AcceptedLanguage, acceptedLanguageArray } from "shared";

// I kind of over did it here but basically, if you want to add a new translation file just add it's filename to the namespacesArray
// And if you want to add a language to the app, just add its lang code to the acceptedLanguageArray

// Types and method needed to build the resources from the arrays

export const namespacesArray = ["general", "patients", "change-password"];
export type Namespace = typeof namespacesArray[number];
type Resource = {
  [k in Namespace]: { string: string };
};

const mapNamespaceToFile = (ns: Namespace, lang: AcceptedLanguage) => require(`./${lang}/${ns}.json`);

// Generate resource object from namespaces array and accepted language array

let resources: { [k in AcceptedLanguage]: Resource } = { en: {}, fr: {} };

acceptedLanguageArray.forEach((language) => {
  let resourceDictionary: Resource = {};
  namespacesArray.forEach((ns) => (resourceDictionary[ns] = mapNamespaceToFile(ns, language)));
  resources[language] = resourceDictionary;
});

// Build i18n for the entire project

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en",
    defaultNS: "general",
    keySeparator: ".",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
