export const generationEnumerationStringFromArray = (array: string[], andWord: string = "&") => {
  if (array.length === 0) return "";
  if (array.length === 1) return array[0];

  const lastElement = array.pop();
  return `${array.join(", ")} ${andWord} ${lastElement}`;
};

export const capitalizeFirstLetter = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};
