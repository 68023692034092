import React from "react";

import { LoginPage, PatientsPage, PatientFilePage, ChangePasswordPage } from "../pages";

export const unauthenticatedRoutes = {
  login: {
    path: "/login",
    element: <LoginPage />,
  },
  changePassword: {
    path: "/change-password",
    element: <ChangePasswordPage />,
  },
};

export const authenticatedRoutes = {
  patients: {
    path: "/patients",
    element: <PatientsPage />,
  },
  patientFile: {
    path: "/patients/:id",
    element: <PatientFilePage />,
  },
};

export const defaultUnauthenticatedRoute = unauthenticatedRoutes.login;
export const defaultAuthenticatedRoute = authenticatedRoutes.patients;

export const unauthenticatedRoutesArray = Object.values(unauthenticatedRoutes);
export const authenticatedRoutesArray = Object.values(authenticatedRoutes);
