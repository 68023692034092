import { action, computed, flow, makeObservable, observable } from "mobx";
import _ from "lodash";
import { RootStore } from "./root";
import { LoginResPayload } from "shared";

const AUTH_USER_TOKEN = "AUTH_USER_TOKEN";

export class AuthStore {
  @observable authToken: string | null = null;

  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.authToken = localStorage.getItem(AUTH_USER_TOKEN);
  }

  @computed
  get isAuthenticated() {
    return !_.isNil(this.authToken);
  }

  login = flow(function* (this: AuthStore, email: string, password: string) {
    const loginResPayload = yield this.rootStore.executeRequest<LoginResPayload>({
      method: "post",
      url: `/auth/login`,
      data: { email, password },
    });

    if ("accessToken" in loginResPayload) {
      this.authToken = loginResPayload.accessToken;
      localStorage.setItem(AUTH_USER_TOKEN, this.authToken as string);
    }

    return loginResPayload;
  });

  resetPassword = flow(function* (this: AuthStore, token: string, email: string, newPassword: string) {
    const resetPasswordPayload = yield this.rootStore.executeRequest<LoginResPayload>({
      method: "put",
      url: `/auth/force-reset-password`,
      data: { email, newPassword },
      headers: { "session-Key": token },
    });

    this.authToken = resetPasswordPayload.accessToken;
    localStorage.setItem(AUTH_USER_TOKEN, this.authToken as string);
  });

  @action
  logout = () => {
    this.authToken = null;
    localStorage.removeItem(AUTH_USER_TOKEN);
    sessionStorage.removeItem("email");
  };
}
