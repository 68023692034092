import { computed, flow, makeObservable, observable } from "mobx";
import { Assessment, AllPatientsResPaylaod, Patient } from "shared";
import { RootStore } from "./root";

// TODO: TO DELETE: Mock data

export class PatientsStore {
  patientsPerPage: number = 10;

  @observable patients: Patient[] | null = null;
  @observable patientCount: number = 0;

  @computed
  get pageCount() {
    return Math.ceil(this.patientCount / this.patientsPerPage);
  }

  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  fetchPatients = flow(function* (this: PatientsStore, page: number, nameFilter: string) {
    const offset = (page - 1) * this.patientsPerPage;
    const allPatientsAndCount = yield this.rootStore.executeRequest<AllPatientsResPaylaod>({
      method: "get",
      url: `/patients/?offset=${offset}&limit=${this.patientsPerPage}&fullName=${nameFilter}`,
    });

    this.patientCount = allPatientsAndCount.totalCount;
    this.patients = allPatientsAndCount.patients;
  });

  fetchSinglePatient = flow(function* (this: PatientsStore, id: string) {
    const patientResPaylaod = yield this.rootStore.executeRequest<Patient>({ method: "get", url: `/patients/${id}` });
    return patientResPaylaod as Patient;
  });

  fetchPatientAssessment = flow(function* (this: PatientsStore, patientId: string, assessmentId: number) {
    const assessmentResPayload = yield this.rootStore.executeRequest({
      method: "get",
      url: `/patients/${patientId}/assessments/${assessmentId}`,
    });
    return assessmentResPayload as Assessment;
  });
}
