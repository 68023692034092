import styled from "styled-components";
import { Button, Container, Field, Input, spacing } from "ui-kit";
import { Form, Formik, Field as FormikField, FieldProps } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { PageWrapper, PasswordValidator, Row } from "../../components";
import { useAuthStore } from "../../stores";
import useQueryParams from "../../utils/use-query-params";
import { defaultUnauthenticatedRoute } from "../../utils/routes";

const ChangePasswordContainer = styled(Container)`
  margin-top: ${spacing.xxl} !important;

  & h1 {
    margin-bottom: ${spacing.l};
  }

  ul {
    margin-top: 0;
  }
`;

export interface ChangePasswordFormValues {
  newPassword: string;
  confirmNewPassword: string;
}

export const ChangePasswordPage = () => {
  const { t: tg } = useTranslation("general");
  const { t } = useTranslation("change-password");
  const authStore = useAuthStore();
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const email = queryParams.get("email");
  const token = queryParams.get("token");

  if (email == null || token == null) navigate(defaultUnauthenticatedRoute.path);

  // Network

  const { mutateAsync: resetPassword, isLoading } = useMutation(({ newPassword }: ChangePasswordFormValues) =>
    authStore.resetPassword(token!, email!, newPassword)
  );

  // Renedering

  const validationSchema = Yup.object().shape({
    newPassword: PasswordValidator.validationSchema,
    confirmNewPassword: Yup.string()
      .required(tg("requiredFieldError"))
      .oneOf([Yup.ref("newPassword"), null], t("errors.passwordsDontMatch")),
  });

  return (
    <PageWrapper withNav lightBackground>
      <ChangePasswordContainer>
        <h1>{t("newPasswordTitle")}</h1>
        <Formik<ChangePasswordFormValues>
          initialValues={{ newPassword: "", confirmNewPassword: "" }}
          validationSchema={validationSchema}
          onSubmit={(values) => resetPassword(values)}>
          {({ errors, touched, isValid, values }) => (
            <Form>
              <Row>
                <Field label={t("newPasswordLabel")}>
                  <>
                    <PasswordValidator password={values.newPassword} />
                    <FormikField name="newPassword">
                      {({ field }: FieldProps) => (
                        <Input
                          {...field}
                          type="password"
                          isInvalid={touched.newPassword && errors.newPassword != null}
                        />
                      )}
                    </FormikField>
                  </>
                </Field>
              </Row>
              <Row>
                <Field
                  label={t("confirmNewPasswordLabel")}
                  error={touched.confirmNewPassword ? errors.confirmNewPassword : undefined}>
                  <FormikField name="confirmNewPassword">
                    {({ field }: FieldProps) => (
                      <Input
                        {...field}
                        type="password"
                        isInvalid={touched.confirmNewPassword && errors.confirmNewPassword != null}
                      />
                    )}
                  </FormikField>
                </Field>
              </Row>
              <Row>
                <Button appearance="primary" type="submit" disabled={!isValid} isLoading={isLoading}>
                  {tg("submit")}
                </Button>
              </Row>
            </Form>
          )}
        </Formik>
      </ChangePasswordContainer>
    </PageWrapper>
  );
};
