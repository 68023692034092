import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { lowerCaseRegex, numberRegex, specialCharacterRegex, upperCaseRegex } from "../../utils/regex";

export const MIN_LENGTH = 8;

export interface PasswordValidatorProps {
  password: string;
}

export const PasswordValidator = ({ password }: PasswordValidatorProps) => {
  const { t } = useTranslation("change-password");

  const minLengthValid = Yup.string().min(MIN_LENGTH).isValidSync(password);
  const upperCaseValid = Yup.string().matches(upperCaseRegex).isValidSync(password);
  const lowerCaseValid = Yup.string().matches(lowerCaseRegex).isValidSync(password);
  const numberValid = Yup.string().matches(numberRegex).isValidSync(password);
  const specialCharacterValid = Yup.string().matches(specialCharacterRegex).isValidSync(password);

  const validStyle = { textDecoration: "line-through" };

  return (
    <ul>
      <li>
        <label style={minLengthValid ? validStyle : undefined}>{t("errors.minLength")}</label>
      </li>
      <li>
        <label style={upperCaseValid ? validStyle : undefined}>{t("errors.upperCase")}</label>
      </li>
      <li>
        <label style={lowerCaseValid ? validStyle : undefined}>{t("errors.lowerCase")}</label>
      </li>
      <li>
        <label style={numberValid ? validStyle : undefined}>{t("errors.number")}</label>
      </li>
      <li>
        <label style={specialCharacterValid ? validStyle : undefined}>{t("errors.specialCharacter")}</label>
      </li>
    </ul>
  );
};

PasswordValidator.validationSchema = Yup.string()
  .min(MIN_LENGTH)
  .matches(upperCaseRegex)
  .matches(lowerCaseRegex)
  .matches(numberRegex)
  .matches(specialCharacterRegex);
