import { TFunction } from "i18next";
import { Duration } from "luxon";

/**
 * Function that converts the duration in seconds into duration formatted text including the units of minutes and seconds
 * @param durationInSeconds
 * @returns Duration formated text including minutes and seconds
 */
export const getDurationWithUnits = (durationInSeconds: number, t: TFunction): string => {
  return Duration.fromMillis(durationInSeconds * 1000).toFormat(`m '${t("minutes")}' s '${t("seconds")}'`);
};
