import React from "react";
import { observer } from "mobx-react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useAuthStore } from "./stores";
import {
  unauthenticatedRoutesArray,
  authenticatedRoutesArray,
  defaultAuthenticatedRoute,
  defaultUnauthenticatedRoute,
} from "./utils/routes";

const App = observer(() => {
  const authStore = useAuthStore();
  const routes = authStore.isAuthenticated ? authenticatedRoutesArray : unauthenticatedRoutesArray;
  const defaultRoute = authStore.isAuthenticated ? defaultAuthenticatedRoute : defaultUnauthenticatedRoute;

  return (
    <Routes>
      {routes.map(({ path, element }) => (
        <Route path={path} element={element} />
      ))}
      <Route path="/*" element={<Navigate replace to={defaultRoute.path} />} />
    </Routes>
  );
});

export default App;
