import { ImportIcon } from "evergreen-ui";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Button } from "ui-kit";
import { useStore } from "../../stores";
import { toaster } from "evergreen-ui";
import { DateTime } from "luxon";
import { parse } from "json2csv";

interface PatientsExportButtonProps {
  patientId?: string | undefined;
  fullName?: string | undefined;
}

const PatientsExportButton = ({ patientId = undefined, fullName = undefined }: PatientsExportButtonProps) => {
  const { t, i18n } = useTranslation("patients");
  const { language } = i18n;
  const rootStore = useStore();
  const url = patientId ? `/patients/form-entries/csv?patientId=${patientId}` : `/patients/form-entries/csv`;

  const {
    refetch,
    isRefetching,
    data: patientsInJSONFormat,
  } = useQuery(
    "fetch-patients-csv",
    async () => {
      return await rootStore.executeRequest({
        method: "get",
        url,
      });
    },
    { refetchOnWindowFocus: false }
  );

  const onExport = async () => {
    await refetch();

    try {
      const patientsInCsvFormat = parse(patientsInJSONFormat as object[]);

      // Create file using Blob then download it
      var downloadLink = document.createElement("a");
      var blob = new Blob(["\ufeff", patientsInCsvFormat]);
      var url = URL.createObjectURL(blob);
      downloadLink.href = url;
      const dateTimeNow = DateTime.now().setLocale(language).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS);
      downloadLink.download = patientId ? `${fullName}-${dateTimeNow}.csv` : `patients-${dateTimeNow}.csv`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      toaster.danger(t("exportPatientsError"));
    }
  };

  return (
    <Button size="small" iconBefore={<ImportIcon />} isLoading={isRefetching} onClick={onExport}>
      {t("export")}
    </Button>
  );
};

export default PatientsExportButton;
